<script>
import { SuawMainContent, SuawSubheaderTwo, SuawSection, SuawCarouselRows, SuawDivider, SuawInputGroup } from "@/components";
import ChapterGoogleSearchBarPipe from "./components/ChapterGoogleSearchBarPipe.vue";
import BecomeOrganizer from "@/features/common/components/BecomeOrganizer.vue";
import { ChapterDiscovery } from "./operations.gql";
import { getGPS } from "@/utils/geo/location.js";
import ChapterDiscoverySkeletonCard from "./components/ChapterDiscoverySkeletonCard.vue";
import chapterDiscoveryImage from "../../../public/img/home/chapter-discovery-image.svg";
import { userTimezone } from "@/utils/formatting/dates.js";
import { timeZoneToCity } from "@/utils/geo/timezone.js";

export default {
  name: "ChapterDiscovery",
  components: {
    BecomeOrganizer,
    SuawMainContent,
    SuawSubheaderTwo,
    SuawSection,
    SuawCarouselRows,
    SuawDivider,
    ChapterGoogleSearchBarPipe,
    SuawInputGroup,
    ChapterDiscoverySkeletonCard
  },
  data() {
    return {
      isLoading: true,
      chapterDiscoveryImage,
      rawChapterData: [],
      chapterCards: [],
      //[99,999] is the fake global "place"
      searchAddress: undefined,
      searchLat: 99,
      searchLng: 999,
      searchDescription: "You",
      searchTerm: "",
      timezone: userTimezone || "Etc/Universal",
      isFilterActive: false
    };
  },
  apollo: {
    chapterCards: {
      query: ChapterDiscovery,
      variables() {
        return {
          lat: this.searchLat,
          lng: this.searchLng,
          unitSystem: this.$auth.user ? this.$auth.user.unit_system : null, //backend will determine from lat/lng when null
          timezone: this.timezone
        };
      },
      skip() {
        return !this.isSearchReady;
      },
      update(data) {
        if (data && data.chapterDiscovery && data.chapterDiscovery.value && data.chapterDiscovery.value.length > 0) {
          // Store raw data for filtering
          this.rawChapterData = data.chapterDiscovery.value.filter(x => x.place.lat !== 99 && x.place.lng !== 999);
          // Return mapped data without filtering
          return this.rawChapterData.map(c => ({
            id: c.id,
            thumbnail: c.photoUrl,
            heading: c.title,
            slug: c.slug,
            meetupUrl: !c.activeAt && c.meetupSlug ? `https://meetup.com/${c.meetupSlug}` : null,
            chipItems: [
              { status: "default", icon: "IconLocation", label: `${c.place.city}, ${c.place.region}` },
              { status: "default", icon: "IconMap", label: `${Math.round(c.place.distance)} ${c.place.distanceUnits}` },
              { status: "info", icon: "IconUser", label: `${c.memberCount}` }
            ]
          }));
        }
        return [];
      },
      result() {
        this.isLoading = false;
      }
    }
  },
  computed: {
    isSearchReady() {
      return this.searchLat !== 99 && this.searchLng !== 999;
    },
    carouselHeading() {
      return `Chapters Near ${this.searchDescription}`;
    },
    filteredChapterCards() {
      if (!this.isFilterActive || !this.searchTerm) {
        return this.chapterCards;
      }

      const searchTermLower = this.searchTerm.toLowerCase();
      const filteredRawData = this.rawChapterData.filter(
        chapter =>
          chapter.title.toLowerCase().includes(searchTermLower) ||
          (chapter.place.city && chapter.place.city.toLowerCase().includes(searchTermLower)) ||
          (chapter.place.region && chapter.place.region.toLowerCase().includes(searchTermLower)) ||
          (chapter.place.country && chapter.place.country.toLowerCase().includes(searchTermLower))
      );

      return filteredRawData.map(c => ({
        id: c.id,
        thumbnail: c.photoUrl,
        heading: c.title,
        slug: c.slug,
        meetupUrl: !c.activeAt && c.meetupSlug ? `https://meetup.com/${c.meetupSlug}` : null,
        chipItems: [
          { status: "default", icon: "IconLocation", label: `${c.place.city}, ${c.place.region}` },
          { status: "default", icon: "IconMap", label: `${Math.round(c.place.distance)} ${c.place.distanceUnits}` },
          { status: "info", icon: "IconUser", label: `${c.memberCount}` }
        ]
      }));
    }
  },
  created() {
    getGPS(position => {
      if (position !== null) {
        if (this.searchLat === 99 || this.searchLng == 999) {
          this.searchLat = position.lat;
          this.searchLng = position.lng;
        }
      } else {
        // If GPS fails, use timezone-based city
        this.searchDescription = timeZoneToCity[userTimezone] || "You";
      }
    });
  },
  methods: {
    onPlaceSelected(o) {
      this.searchLat = o.place.geometry.location.lat();
      this.searchLng = o.place.geometry.location.lng();
      //this.searchDescription = place.formatted_address; //sometimes contains ugly data that isn't in the search text
      this.searchDescription = o.text;
      this.isFilterActive = false;
    },
    onSearchInput(value) {
      this.searchTerm = value;
    },
    onSearchFocus() {
      //bad experience; taking out the filter
      //this.isFilterActive = true;
    },
    onChapterClicked(chapterId) {
      const chapter = this.chapterCards.find(card => card.id === chapterId);
      if (chapter) {
        if (chapter.meetupUrl) {
          window.open(chapter.meetupUrl, "_blank");
        } else {
          this.$router.push({
            name: "Chapter",
            params: {
              chapterId: chapterId,
              chapterSlug: chapter.slug
            }
          });
        }
      } else {
        this.$log.error("Chapter not found!");
      }
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true" :small-top-margin="true">
    <SuawSubheaderTwo
      title="Chapters"
      description="Explore our global network of writing chapters and find one near you. Join a community, attend an event, and connect with fellow writers who will support you in your writing journey. Can't find an event that works for you? Become an organizer and create your own!"
      :image-source="chapterDiscoveryImage"
      image-alt="Two writers in discussion at a desk."
    >
    </SuawSubheaderTwo>
    <SuawInputGroup direction="column" group-gap="triple">
      <ChapterGoogleSearchBarPipe
          placeholder="Enter your city or zip code to find chapters near you"
          @submit="onPlaceSelected"
          @filter="onSearchInput"
          @focus="onSearchFocus"
        />
      <SuawCarouselRows v-if="isLoading" :carousel-heading="carouselHeading">
        <template #cards>
          <ChapterDiscoverySkeletonCard v-for="n in 8" :key="n" />
        </template>
      </SuawCarouselRows>
      <SuawCarouselRows v-else :carousel-heading="carouselHeading" card-type="CardChapter" :cards="filteredChapterCards" @click="onChapterClicked" />
    </SuawInputGroup>
    <SuawSection>
      <SuawDivider />
    </SuawSection>
    <SuawSection>
      <BecomeOrganizer />
    </SuawSection>
  </SuawMainContent>
</template>
