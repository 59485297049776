<script>
/* global google */
import ChapterSearchBarView from "./ChapterSearchBarView.vue";

export default {
  name: "ChapterGoogleSearchBarPipe",
  components: {
    ChapterSearchBarView
  },
  props: {
    placeholder: {
      type: String,
      default: "Enter your city or zip code to find chapters near you"
    }
  },
  data() {
    return {
      searchTerm: "",
      results: [],
      service: null,
      debounceTimer: null,
      selectedIndex: -1
    };
  },
  mounted() {
    if (!window.google) {
      console.error("Google Maps API not loaded");
      return;
    }
    this.service = new google.maps.places.AutocompleteService();
  },
  methods: {
    handleInput(value) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.searchTerm = value;
        if (!this.searchTerm) {
          this.results = [];
          this.$emit("filter", "");
          return;
        }

        // Filter existing chapters
        const searchTermLower = this.searchTerm.toLowerCase();
        this.$emit("filter", searchTermLower);

        // Query Google Places
        if (this.service) {
          this.service.getPlacePredictions(
            { input: this.searchTerm },
            (predictions, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                this.results = predictions.map(pred => ({
                  text: pred.description,
                  value: pred.place_id,
                  isPlace: true
                }));
              } else {
                this.results = [];
              }
            }
          );
        }
      }, 300); // 300ms debounce
    },
    handleSelect(item) {
      if (item.isPlace) {
        const div = document.createElement("div");
        const placeService = new google.maps.places.PlacesService(div);
        placeService.getDetails(
          {
            placeId: item.value,
            fields: ["place_id", "formatted_address", "geometry"]
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              this.$emit("submit", { place, text: item.text });
              this.searchTerm = item.text;
              this.results = [];
            }
          }
        );
      }
    },
    handleBlur() {
      // Small delay to allow click events on results to fire first
      setTimeout(() => {
        this.results = [];
      }, 200);
    },
    handleEscape() {
      this.results = [];
      this.selectedIndex = -1;
    },
    handleKeyNavigation(key) {
      if (!this.results.length) return;
      
      if (key === "ArrowDown") {
        this.selectedIndex = Math.min(this.results.length - 1, this.selectedIndex + 1);
      } else if (key === "ArrowUp") {
        this.selectedIndex = Math.max(-1, this.selectedIndex - 1);
      } else if (key === "Enter" && this.selectedIndex >= 0) {
        this.handleSelect(this.results[this.selectedIndex]);
      }
    },
    handleFocus() {
      this.$emit("focus");
      //this.searchTerm = "";
      if (this.searchTerm) {
        this.handleInput(this.searchTerm);
      }
    },
    handleSubmit() {
      if (this.selectedIndex >= 0 && this.results.length > 0) {
        this.handleSelect(this.results[this.selectedIndex]);
      }
    }
  }
};
</script>

<template>
  <ChapterSearchBarView
    :search-term="searchTerm"
    :results="results"
    :placeholder="placeholder"
    :selected-index="selectedIndex"
    @input="handleInput"
    @select="handleSelect"
    @blur="handleBlur"
    @escape="handleEscape"
    @focus="handleFocus"
    @submit="handleSubmit"
    @key-navigation="handleKeyNavigation"
  />
</template>
