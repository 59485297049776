//map of time zone to each time zone's most populous city, using data from official IANA tab files
//generated by ChatGPT 4o, in stages, due to length restrictions
export const timeZoneToCity = {
  "Africa/Abidjan": "Abidjan, Côte d'Ivoire",
  "Africa/Accra": "Accra, Ghana",
  "Africa/Addis_Ababa": "Addis Ababa, Ethiopia",
  "Africa/Algiers": "Algiers, Algeria",
  "Africa/Asmara": "Asmara, Eritrea",
  "Africa/Bamako": "Bamako, Mali",
  "Africa/Bangui": "Bangui, Central African Republic",
  "Africa/Banjul": "Banjul, Gambia",
  "Africa/Bissau": "Bissau, Guinea-Bissau",
  "Africa/Blantyre": "Blantyre, Malawi",
  "Africa/Brazzaville": "Brazzaville, Republic of the Congo",
  "Africa/Bujumbura": "Bujumbura, Burundi",
  "Africa/Cairo": "Cairo, Egypt",
  "Africa/Casablanca": "Casablanca, Morocco",
  "Africa/Ceuta": "Ceuta, Spain",
  "Africa/Conakry": "Conakry, Guinea",
  "Africa/Dakar": "Dakar, Senegal",
  "Africa/Dar_es_Salaam": "Dar es Salaam, Tanzania",
  "Africa/Djibouti": "Djibouti, Djibouti",
  "Africa/Douala": "Douala, Cameroon",
  "Africa/El_Aaiun": "El Aaiún, Western Sahara",
  "Africa/Freetown": "Freetown, Sierra Leone",
  "Africa/Gaborone": "Gaborone, Botswana",
  "Africa/Harare": "Harare, Zimbabwe",
  "Africa/Johannesburg": "Johannesburg, South Africa",
  "Africa/Juba": "Juba, South Sudan",
  "Africa/Kampala": "Kampala, Uganda",
  "Africa/Khartoum": "Khartoum, Sudan",
  "Africa/Kigali": "Kigali, Rwanda",
  "Africa/Kinshasa": "Kinshasa, Democratic Republic of the Congo",
  "Africa/Lagos": "Lagos, Nigeria",
  "Africa/Libreville": "Libreville, Gabon",
  "Africa/Lome": "Lomé, Togo",
  "Africa/Luanda": "Luanda, Angola",
  "Africa/Lubumbashi": "Lubumbashi, Democratic Republic of the Congo",
  "Africa/Lusaka": "Lusaka, Zambia",
  "Africa/Malabo": "Malabo, Equatorial Guinea",
  "Africa/Maputo": "Maputo, Mozambique",
  "Africa/Maseru": "Maseru, Lesotho",
  "Africa/Mbabane": "Mbabane, Eswatini",
  "Africa/Mogadishu": "Mogadishu, Somalia",
  "Africa/Monrovia": "Monrovia, Liberia",
  "Africa/Nairobi": "Nairobi, Kenya",
  "Africa/Ndjamena": "N'Djamena, Chad",
  "Africa/Niamey": "Niamey, Niger",
  "Africa/Nouakchott": "Nouakchott, Mauritania",
  "Africa/Ouagadougou": "Ouagadougou, Burkina Faso",
  "Africa/Porto-Novo": "Porto-Novo, Benin",
  "Africa/Sao_Tome": "São Tomé, São Tomé and Príncipe",
  "Africa/Tripoli": "Tripoli, Libya",
  "Africa/Tunis": "Tunis, Tunisia",
  "Africa/Windhoek": "Windhoek, Namibia",
  "Europe/Amsterdam": "Amsterdam, Netherlands",
  "Europe/Andorra": "Andorra la Vella, Andorra",
  "Europe/Astrakhan": "Astrakhan, Russia",
  "Europe/Athens": "Athens, Greece",
  "Europe/Belgrade": "Belgrade, Serbia",
  "Europe/Berlin": "Berlin, Germany",
  "Europe/Bratislava": "Bratislava, Slovakia",
  "Europe/Brussels": "Brussels, Belgium",
  "Europe/Bucharest": "Bucharest, Romania",
  "Europe/Budapest": "Budapest, Hungary",
  "Europe/Busingen": "Büsingen am Hochrhein, Germany",
  "Europe/Chisinau": "Chișinău, Moldova",
  "Europe/Copenhagen": "Copenhagen, Denmark",
  "Europe/Dublin": "Dublin, Ireland",
  "Europe/Gibraltar": "Gibraltar",
  "Europe/Guernsey": "St. Peter Port, Guernsey",
  "Europe/Helsinki": "Helsinki, Finland",
  "Europe/Isle_of_Man": "Douglas, Isle of Man",
  "Europe/Istanbul": "Istanbul, Turkey",
  "Europe/Jersey": "Saint Helier, Jersey",
  "Europe/Kaliningrad": "Kaliningrad, Russia",
  "Europe/Kiev": "Kyiv, Ukraine",
  "Europe/Kirov": "Kirov, Russia",
  "Europe/Lisbon": "Lisbon, Portugal",
  "Europe/Ljubljana": "Ljubljana, Slovenia",
  "Europe/London": "London, United Kingdom",
  "Europe/Luxembourg": "Luxembourg City, Luxembourg",
  "Europe/Madrid": "Madrid, Spain",
  "Europe/Malta": "Valletta, Malta",
  "Europe/Mariehamn": "Mariehamn, Åland Islands",
  "Europe/Minsk": "Minsk, Belarus",
  "Europe/Monaco": "Monaco",
  "Europe/Moscow": "Moscow, Russia",
  "Europe/Oslo": "Oslo, Norway",
  "Europe/Paris": "Paris, France",
  "Europe/Podgorica": "Podgorica, Montenegro",
  "Europe/Prague": "Prague, Czech Republic",
  "Europe/Riga": "Riga, Latvia",
  "Europe/Rome": "Rome, Italy",
  "Europe/Samara": "Samara, Russia",
  "Europe/San_Marino": "San Marino",
  "Europe/Sarajevo": "Sarajevo, Bosnia and Herzegovina",
  "Europe/Saratov": "Saratov, Russia",
  "Europe/Simferopol": "Simferopol, Crimea",
  "Europe/Skopje": "Skopje, North Macedonia",
  "Europe/Sofia": "Sofia, Bulgaria",
  "Europe/Stockholm": "Stockholm, Sweden",
  "Europe/Tallinn": "Tallinn, Estonia",
  "Europe/Tirane": "Tirana, Albania",
  "Europe/Ulyanovsk": "Ulyanovsk, Russia",
  "Europe/Uzhgorod": "Uzhhorod, Ukraine",
  "Europe/Vaduz": "Vaduz, Liechtenstein",
  "Europe/Vatican": "Vatican City",
  "Europe/Vienna": "Vienna, Austria",
  "Europe/Vilnius": "Vilnius, Lithuania",
  "Europe/Volgograd": "Volgograd, Russia",
  "Europe/Warsaw": "Warsaw, Poland",
  "Europe/Zagreb": "Zagreb, Croatia",
  "Europe/Zaporozhye": "Zaporizhzhia, Ukraine",
  "Europe/Zurich": "Zurich, Switzerland",
  "Asia/Aden": "Aden, Yemen",
  "Asia/Almaty": "Almaty, Kazakhstan",
  "Asia/Amman": "Amman, Jordan",
  "Asia/Anadyr": "Anadyr, Russia",
  "Asia/Aqtau": "Aktau, Kazakhstan",
  "Asia/Aqtobe": "Aktobe, Kazakhstan",
  "Asia/Ashgabat": "Ashgabat, Turkmenistan",
  "Asia/Atyrau": "Atyrau, Kazakhstan",
  "Asia/Baghdad": "Baghdad, Iraq",
  "Asia/Bahrain": "Manama, Bahrain",
  "Asia/Baku": "Baku, Azerbaijan",
  "Asia/Bangkok": "Bangkok, Thailand",
  "Asia/Barnaul": "Barnaul, Russia",
  "Asia/Beirut": "Beirut, Lebanon",
  "Asia/Bishkek": "Bishkek, Kyrgyzstan",
  "Asia/Brunei": "Bandar Seri Begawan, Brunei",
  "Asia/Chita": "Chita, Russia",
  "Asia/Choibalsan": "Choibalsan, Mongolia",
  "Asia/Colombo": "Colombo, Sri Lanka",
  "Asia/Damascus": "Damascus, Syria",
  "Asia/Dhaka": "Dhaka, Bangladesh",
  "Asia/Dili": "Dili, Timor-Leste",
  "Asia/Dubai": "Dubai, UAE",
  "Asia/Dushanbe": "Dushanbe, Tajikistan",
  "Asia/Famagusta": "Famagusta, Cyprus",
  "Asia/Gaza": "Gaza City, Palestine",
  "Asia/Hebron": "Hebron, Palestine",
  "Asia/Ho_Chi_Minh": "Ho Chi Minh City, Vietnam",
  "Asia/Hong_Kong": "Hong Kong, China",
  "Asia/Hovd": "Hovd, Mongolia",
  "Asia/Irkutsk": "Irkutsk, Russia",
  "Asia/Jakarta": "Jakarta, Indonesia",
  "Asia/Jayapura": "Jayapura, Indonesia",
  "Asia/Jerusalem": "Jerusalem, Israel",
  "Asia/Kabul": "Kabul, Afghanistan",
  "Asia/Kamchatka": "Petropavlovsk-Kamchatsky, Russia",
  "Asia/Karachi": "Karachi, Pakistan",
  "Asia/Kathmandu": "Kathmandu, Nepal",
  "Asia/Kolkata": "Kolkata, India",
  "Asia/Krasnoyarsk": "Krasnoyarsk, Russia",
  "Asia/Kuala_Lumpur": "Kuala Lumpur, Malaysia",
  "Asia/Kuching": "Kuching, Malaysia",
  "Asia/Kuwait": "Kuwait City, Kuwait",
  "Asia/Macau": "Macau, China",
  "Asia/Magadan": "Magadan, Russia",
  "Asia/Makassar": "Makassar, Indonesia",
  "Asia/Manila": "Manila, Philippines",
  "Asia/Muscat": "Muscat, Oman",
  "Asia/Nicosia": "Nicosia, Cyprus",
  "Asia/Novokuznetsk": "Novokuznetsk, Russia",
  "Asia/Novosibirsk": "Novosibirsk, Russia",
  "Asia/Omsk": "Omsk, Russia",
  "Asia/Phnom_Penh": "Phnom Penh, Cambodia",
  "Asia/Pyongyang": "Pyongyang, North Korea",
  "Asia/Qatar": "Doha, Qatar",
  "Asia/Riyadh": "Riyadh, Saudi Arabia",
  "Asia/Samarkand": "Samarkand, Uzbekistan",
  "Asia/Seoul": "Seoul, South Korea",
  "Asia/Shanghai": "Shanghai, China",
  "Asia/Singapore": "Singapore",
  "Asia/Taipei": "Taipei, Taiwan",
  "Asia/Tashkent": "Tashkent, Uzbekistan",
  "Asia/Tbilisi": "Tbilisi, Georgia",
  "Asia/Tehran": "Tehran, Iran",
  "Asia/Tokyo": "Tokyo, Japan",
  "Asia/Ulaanbaatar": "Ulaanbaatar, Mongolia",
  "Asia/Vientiane": "Vientiane, Laos",
  "Asia/Yakutsk": "Yakutsk, Russia",
  "Asia/Yekaterinburg": "Yekaterinburg, Russia",
  "Australia/Adelaide": "Adelaide, Australia",
  "Australia/Brisbane": "Brisbane, Australia",
  "Australia/Darwin": "Darwin, Australia",
  "Australia/Eucla": "Eucla, Australia",
  "Australia/Hobart": "Hobart, Australia",
  "Australia/Lord_Howe": "Lord Howe Island, Australia",
  "Australia/Melbourne": "Melbourne, Australia",
  "Australia/Perth": "Perth, Australia",
  "Australia/Sydney": "Sydney, Australia",
  "Pacific/Apia": "Apia, Samoa",
  "Pacific/Auckland": "Auckland, New Zealand",
  "Pacific/Bougainville": "Bougainville, Papua New Guinea",
  "Pacific/Chatham": "Chatham Islands, New Zealand",
  "Pacific/Easter": "Hanga Roa, Easter Island, Chile",
  "Pacific/Fakaofo": "Fakaofo, Tokelau",
  "Pacific/Fiji": "Suva, Fiji",
  "Pacific/Funafuti": "Funafuti, Tuvalu",
  "Pacific/Galapagos": "Puerto Baquerizo Moreno, Galápagos, Ecuador",
  "Pacific/Gambier": "Rikitea, Gambier Islands, French Polynesia",
  "Pacific/Guadalcanal": "Honiara, Solomon Islands",
  "Pacific/Guam": "Hagåtña, Guam",
  "Pacific/Honolulu": "Honolulu, Hawaii, USA",
  "Pacific/Kiritimati": "Kiritimati, Kiribati",
  "Pacific/Majuro": "Majuro, Marshall Islands",
  "Pacific/Nauru": "Yaren, Nauru",
  "Pacific/Niue": "Alofi, Niue",
  "Pacific/Norfolk": "Kingston, Norfolk Island",
  "Pacific/Noumea": "Nouméa, New Caledonia",
  "Pacific/Pago_Pago": "Pago Pago, American Samoa",
  "Pacific/Palau": "Ngerulmud, Palau",
  "Pacific/Pitcairn": "Adamstown, Pitcairn Islands",
  "Pacific/Port_Moresby": "Port Moresby, Papua New Guinea",
  "Pacific/Rarotonga": "Avarua, Cook Islands",
  "Pacific/Saipan": "Saipan, Northern Mariana Islands",
  "Pacific/Tahiti": "Papeete, Tahiti, French Polynesia",
  "Pacific/Tarawa": "South Tarawa, Kiribati",
  "Pacific/Tongatapu": "Nukuʻalofa, Tonga",
  "Pacific/Wake": "Wake Island, USA",
  "Pacific/Wallis": "Mata-Utu, Wallis and Futuna",
  "America/Anchorage": "Anchorage, Alaska, USA",
  "America/Asuncion": "Asunción, Paraguay",
  "America/Bogota": "Bogotá, Colombia",
  "America/Buenos_Aires": "Buenos Aires, Argentina",
  "America/Caracas": "Caracas, Venezuela",
  "America/Chicago": "Chicago, USA",
  "America/Denver": "Denver, USA",
  "America/Guatemala": "Guatemala City, Guatemala",
  "America/Halifax": "Halifax, Canada",
  "America/Havana": "Havana, Cuba",
  "America/La_Paz": "La Paz, Bolivia",
  "America/Lima": "Lima, Peru",
  "America/Los_Angeles": "Los Angeles, USA",
  "America/Mexico_City": "Mexico City, Mexico",
  "America/Montreal": "Montreal, Canada",
  "America/New_York": "New York City, USA",
  "America/Panama": "Panama City, Panama",
  "America/Phoenix": "Phoenix, USA",
  "America/Rio_Branco": "Rio Branco, Brazil",
  "America/Santiago": "Santiago, Chile",
  "America/Sao_Paulo": "São Paulo, Brazil",
  "America/Toronto": "Toronto, Canada",
  "America/Vancouver": "Vancouver, Canada",
  "America/Whitehorse": "Whitehorse, Canada"
};
