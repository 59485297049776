<script>
import { SuawIcon, SuawButton } from "@/components";

export default {
  name: "ChapterGoogleSearchBarView",
  components: {
    SuawIcon,
    SuawButton
  },
  props: {
    searchTerm: {
      type: String,
      required: true
    },
    results: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    selectedIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
    handleSelect(item) {
      this.$emit("select", item);
    },
    handleBlur() {
      this.$emit("blur");
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.$emit("escape");
      } else if (["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
        event.preventDefault();
        this.$emit("key-navigation", event.key);
      }
    },
    handleFocus() {
      this.$emit("focus");
    }
  }
};
</script>

<template>
  <div class="suaw-search-input">
    <div class="suaw-search-input__holder">
      <input
        class="suaw-search-input__field"
        :value="searchTerm"
        :placeholder="placeholder"
        @input="handleInput"
        @blur="handleBlur"
        @keydown="handleKeydown"
        @focus="handleFocus"
      >
      <SuawIcon
        icon="IconSearch"
        class="suaw-search-input__icon"
        color="gray"
      />
    </div>
    <div
      v-if="results.length > 0"
      class="suaw-search-input__dropdown"
    >
      <div class="suaw-search-input__box">
        <ul class="suaw-search-input__list">
          <li
            v-for="(item, index) in results"
            :key="item.value"
            class="suaw-search-input__item"
          >
            <div 
              class="suaw-search-input__item-wrapper"
              :class="{ 'is-selected': index === selectedIndex }"
            >
              <SuawButton
                :buttonText="item.text"
                type="secondary-ghost"
                @click="handleSelect(item)"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-search-input {
  background-color: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  border: 1px solid var(--sem-color-border-medium);
  width: 100%;

  &__holder {
    position: relative;
    height: 64px;
    padding: var(--sem-space-base);
    display: flex;
    align-items: center;
  }

  &__field {
    font-family: var(--base-font-family);
    font-size: var(--button-normal-font-size);
    font-weight: var(--button-normal-font-weight);
    height: 100%;
    padding: var(--sem-space-base) var(--sem-space-double);
    padding-left: var(--sem-space-quadruple);
    transition: border-color .25s linear;
    width: 100%;
    border: 0;
    background: transparent;
    color: var(--sem-color-neutral-medium);

    &:focus,
    &:focus-visible {
      outline: 0;
    }
  }

  &__button-wrapper {
    margin-left: var(--sem-space-base);
    margin-right: var(--sem-space-base);
  }

  &__icon {
    left: var(--sem-space-double);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--button-normal-font-size);
  }

  &__dropdown {
    display: block;
    position: relative;
  }

  &__box {
    background: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    box-shadow: var(--soft-shadow-draft);
    padding: var(--sem-space-double);
    position: absolute;
    right: 0;
    max-height: 432px;
    min-width: 200px;
    overflow: auto;
    transition-property: opacity, visibility;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    width: 100%;
    z-index: 97;
  }

  &__list {
    padding-left: var(--sem-space-none);
    padding-right: var(--sem-space-none);
    list-style: none;
    margin: 0;
  }

  &__item {
    width: 100%;
  }

  &__item-wrapper {
    border-radius: var(--sem-radius-md);
    color: var(--sem-color-text-regular);
    display: flex;
    align-items: center;
    font-size: var(--body-copy-font-size);
    padding: var(--sem-space-base);
    text-decoration: none;
    transition-property: background-color, color;
    transition-duration: .25s;
    transition-timing-function: linear;
    justify-content: flex-start;
    width: 100%;

    :deep(.suaw-button) {
      width: 100%;
      justify-content: flex-start;
    }

    &:hover,
    &.is-selected {
      background-color: var(--sem-color-background-light);
      color: var(--sem-color-text-darkest);
    }
  }
}
</style>
