<script>
export default {
  name: "ChapterDiscoveryCardSkeleton",
};
</script>

<template>
  <div class="suaw-chapter-discovery-card-skeleton">
    <div class="suaw-chapter-discovery-card-skeleton__thumbnail"></div>
    <div class="suaw-chapter-discovery-card-skeleton__heading"></div>
    <div class="suaw-chapter-discovery-card-skeleton__chip location-chip"></div>
    <div class="suaw-chapter-discovery-card-skeleton__chip"></div>
    <div class="suaw-chapter-discovery-card-skeleton__chip member-chip"></div>
    <!-- <div class="suaw-chapter-discovery-card-skeleton__description"></div>
    <div class="suaw-chapter-discovery-card-skeleton__chips">
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-discovery-card-skeleton {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-triple);
  min-width: 256px;
  height: 326px;
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: var(--sem-space-base);
  align-items: center;

  &__thumbnail {
    width: 100%;
    height: 146px;
    border-radius: var(--sem-radius-md);
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__heading {
    width: 80%;
    height: 23px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__description {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__chip {
    width: 80px;
    height: 22px;
    border-radius: 12px;
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
.location-chip {
  width: 163px;
}
.member-chip {
  width: 64px;
}
</style>
